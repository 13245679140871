import React from 'react';
import './styles.css';
import profilePicture from './images/profile.jpg'; // Ensure the image is placed in the 'images' folder

function Header() {
  const openMenu = () => {
    document.getElementById("sidemenu").style.right = "0";
  };

  const closeMenu = () => {
    document.getElementById("sidemenu").style.right = "-200px";
  };

  return (
    <div id="header">
      <div className="container">
        <nav>
          <ul id="sidemenu">
            <li><i className="fas fa-home"></i><a href="#header">Home</a></li>
            <li><i className="fas fa-user"></i><a href="#about">About</a></li>
            <li><i className="fas fa-concierge-bell"></i><a href="#services">Services</a></li>
            <li><i className="fas fa-briefcase"></i><a href="#projects">Projects</a></li>
            <li><i className="fas fa-envelope"></i><a href="#contact">Contact</a></li>
            <i className="fas fa-times" onClick={closeMenu}></i>
          </ul>
          <i className="fas fa-bars" onClick={openMenu}></i>
        </nav>
        <div className="header-text">
          <div className="text-background">
            <div className="profile-container">
              <img src={profilePicture} className="profile-picture" alt="Profile" />
            </div>
            <p>Java Software Engineer</p>
            <h1>Welcome to my personal website <span>everyone</span></h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
