import React from 'react';
import './styles.css';

function Projects() {
  return (
    <div id="projects">
      <div className="container">
        <h1 className="sub-title">My Projects</h1>
        <div className="work-list">
          <div className="work">
            <a href="https://dev.to/aws-builders/how-i-built-my-personal-blog-site-using-aws-services-51l7" target="_blank" rel="noopener noreferrer">
              <img src={require('./images/cloud.jpg')} alt="Project" className="project-image" />
            </a>
            <div className="layer">
              <h3>Little About Spring Security</h3>
              <p>How I Built My Personal Blog Site Using AWS Services</p>
              <a href="https://dev.to/aws-builders/how-i-built-my-personal-blog-site-using-aws-services-51l7" className="link-icon">
                <i className="fas fa-external-link-alt"></i> Read the blog
              </a>
            </div>
          </div>
          {/* Add other project blocks here */}
        </div>
      </div>
    </div>
  );
}

export default Projects;
