import React from 'react';
import './styles.css';

function Services() {
  return (
    <div id="services">
      <div className="container">
        <h1 className="sub-title">My Services</h1>
        <div className="services-list">
          <div>
            <i className="fas fa-code"></i>
            <h2>Application Development</h2>
            <h3>Backend Development</h3>
            <ul>
              <li>Crafting dynamic server-side scripts to process business logic, handle requests, and deliver content to clients...</li>
              <li>Design and implementation of robust APIs to facilitate seamless communication between different software components, enhancing functionality and user experience.</li>
              <li>Expertise in database architecture, management, and optimization to ensure efficient data storage, retrieval, and integrity.</li>
            </ul>
            {/* Add other services here */}
          </div>
          {/* Add more service blocks here */}
        </div>
      </div>
    </div>
  );
}

export default Services;
