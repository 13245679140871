// Contact.js in your React project
import React, { useState } from 'react';
import './styles.css';

function Contact() {
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const body = JSON.stringify(Object.fromEntries(formData));

    try {
      const response = await fetch('https://g0q15b8yai.execute-api.us-east-1.amazonaws.com/apig/contact', {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json(); // Parse JSON response

      if (response.ok) {
        setMessage('Message sent successfully: ' + responseData.message);
        setTimeout(() => setMessage(''), 5000);
        e.target.reset();
      } else {
        setMessage('Error sending message: ' + responseData.error);
      }
    } catch (error) {
      console.error('Error!', error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div id="contact">
      <div className="container">
        <div className="row">
          <div className="contact-left">
            <h1 className="sub-title">Contact Me</h1>
            <p><a href="mailto:example@example.com"><i className="fa-solid fa-envelope"></i></a> mikeberhane.mb@gmail.com</p>
            <p><a href="tel:123-456-7890"><i className="fa-sharp fa-solid fa-phone"></i></a> 123-456-7890</p>
            <div className="social-icons">
              <a href="https://facebook.com/"><i className="fab fa-facebook"></i></a>
              <a href="https://twitter.com"><i className="fab fa-twitter-square"></i></a>
              <a href="https://instagram.com"><i className="fab fa-instagram"></i></a>
              <a href="https://www.linkedin.com/in/your-profile/"><i className="fab fa-linkedin"></i></a>
            </div>
            <a href="/my-cv.pdf" download className="btn btn2">Download CV</a>
          </div>
          <div className="contact-right">
            <form onSubmit={handleSubmit} name="contact-form">
              <input type="text" name="Name" placeholder="Your Name" required />
              <input type="email" name="Email" placeholder="Your Email" required />
              <textarea name="MessageBody" rows="6" placeholder="Your Message"></textarea>
              <button type="submit" className="btn btn2">Submit</button>
            </form>
            <span id="msg">{message}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
