import React from 'react';
import './styles.css';

function About() {
  const openTab = (tabname, event) => {
    let tablinks = document.getElementsByClassName("tab-links");
    let tabcontents = document.getElementsByClassName("tab-contents");

    for (let tablink of tablinks) {
      tablink.classList.remove("active-link");
    }
    for (let tabcontent of tabcontents) {
      tabcontent.classList.remove("active-tab");
    }
    event.currentTarget.classList.add("active-link");
    document.getElementById(tabname).classList.add("active-tab");
  };

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="about-col-1">
            {/* <img src="images/" alt="About" /> */}
          </div>
          <div className="about-col-2">
            <h1 className="sub-title">About Me</h1>
            <p>Hi! My name is Berhane Micheal...</p>
            <p>I am a motivated Java Developer...</p>
            <p>I've been working in Java for the past 5 years...</p>
            <div className="tab-titles">
              <p className="tab-links active-link" onClick={(event) => openTab('skills', event)}>Skills</p>
              <p className="tab-links" onClick={(event) => openTab('experience', event)}>Experience</p>
              <p className="tab-links" onClick={(event) => openTab('education', event)}>Education</p>
            </div>

            <div className="tab-contents active-tab" id="skills">
              <ul className="vertical-skills">
                <li><span>Java</span><span id="percent">90%</span></li>
                <li><span>JavaScript</span><span id="percent">70%</span></li>
                <li><span>MySQL</span><span id="percent">95%</span></li>
                <li><span>MongoDB</span><span id="percent">95%</span></li>
                <li><span>AWS</span><span id="percent">70%</span></li>
                <li><span>Spring Framework</span><span id="percent">90%</span></li>
                {/* Add other skills here */}
              </ul>
            </div>

            <div className="tab-contents" id="experience">
              <ul>
                <li><span>01/2019 – 04/2022</span><br />Software Engineer at Ministry of Education, Asmara, Eritrea</li>
                <li><span>08/2017 – 10/2018</span><br />Full-Stack Java Developer at Eri-Tel, Asmara, Eritrea</li>
              </ul>
            </div>
            <div className="tab-contents" id="education">
              <ul>
                <li><span>2023-2024</span><br />Masters degree from Mahirishi International University, Fairfeild, IA, USA</li>
                <li><span>2022-2025</span><br />Bachelor's degree from Mai-Nefhi college, Eritrea</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
